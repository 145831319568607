import React from "react";
import Helmet from "react-helmet";

const OGImage = "https://www.bravemark.co/og-image.jpg"

export default function DefaultMeta() {
  const analytics = process.env.USE_ANALYTICS === "true" ? (
    <script defer async data-host="bravemark.co" src="https://liteanalytics.com/lite.js"></script>
  ) : null

  const tolt = process.env.TOLT_ID === "" ? null : (
    <script async src="https://cdn.tolt.io/tolt.js" data-tolt={process.env.TOLT_ID}></script>
  )

  const title = "Actionable Online Brand Guidelines that just work • Bravemark"
  const description = "Design and publish responsive online brand identity guidelines with downloadable assets in 10 minutes or less. Start for Free."
  
  return (
    <Helmet>
      <html lang="en" />
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="description" content={description} />
      <meta name="keywords" content="online brand guidelines, brand identity guidelines, brand identity handoff, brand identity design, branding, brand guidelines, brand presentations, brand identity presentations, logo design presentations" />
      <meta name="robots" content="index, follow" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={OGImage} />
      <meta property="og:url" content="https://www.bravemark.co" />
      <meta property="og:locale" content="en_US"/>
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="Bravemark" />


      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@hellobravemark" />
      <meta name="twitter:image" content={OGImage} />
      <meta name="twitter:url" content="https://www.bravemark.co" />

      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#191921" />
      <meta name="msapplication-TileColor" content="#191921" />
      <meta name="theme-color" content="#0f0f14" />

      {/* alternative to google analytics which is GDPR compliant */}
      { analytics }
      { tolt }      

      {/* Avoid white page */}
      <style type="text/css">{`
        body {
          background-color: #0f0f14;
        }
      `}</style>
    </Helmet>
  )
}