import React, { useEffect, useState } from "react"
import * as moduleStyles from "./PaywallContainer.module.scss"
import Spacer from "../spacer/Spacer"
import { Button } from "../form/Button"
import OnOffButton from "../on-off/OnOffButton"
import Conditional from "../Conditional"
import CheckboxCircleFill from "../icons/CheckboxCircleFill"

const PERIODICITIES = {
  MONTHLY: "monthly",
  YEARLY: "yearly",
}

const BRICK_THEMES = {
  FREE: moduleStyles.free,
  PRO: moduleStyles.pro,
  PRO_PLUS: moduleStyles.proPlus,
}

export default function PaywallContainer(props) {
  const {
    defaultPeriodicity = PERIODICITIES.MONTHLY,
  } = props

  const [periodicity, setPeriodicity] = useState(defaultPeriodicity)
  function setYearly(boolean) {
    console.log("setYearly", boolean, periodicity)
    if (boolean && periodicity === PERIODICITIES.MONTHLY) {
      setPeriodicity(PERIODICITIES.YEARLY)
    } else if (!boolean && periodicity === PERIODICITIES.YEARLY) {
      setPeriodicity(PERIODICITIES.MONTHLY)
    }
  }

  const billedNotice = "Billed " + (periodicity === PERIODICITIES.MONTHLY ? "Monthly" : "Yearly")

  return (
    <div className={moduleStyles.container}>
      <PeriodicityToggle periodicity={periodicity} onClick={(boolean) => setYearly(boolean)} />
      
      <Spacer amount="36" />

      <div className={moduleStyles.bricks}>
        <Brick title="Forever Free"
          theme={BRICK_THEMES.FREE}
          periodicity={periodicity}
          prices={{ [PERIODICITIES.MONTHLY]: "0", [PERIODICITIES.YEARLY]: "0" }}
          currency="USD"
          notice="No Credit Card Required"
          features={[
            "5 Projects",
            "Publish to Portfolio",
            {
              title: "Core Editor Modules",
              description: "Included modules: Intro, Logo, Image, Stack, Typography, Color, Video, Text, Section Title",
            },
          ]}
          buttonLabel="Get Started For Free"
        />
        <Brick title="PRO"
          theme={BRICK_THEMES.PRO}
          periodicity={periodicity}
          prices={{ [PERIODICITIES.MONTHLY]: "10", [PERIODICITIES.YEARLY]: "100" }}
          currency="USD"
          notice={billedNotice}
          features={[
            "10 Projects",
            "All Forever Free Features\u00A0+", // non-breaking space
            "Publish Privately",
            "Asset Downloads",
            "PRO Badge",
          ]}
          buttonLabel="Get Started For Free"
        />
        <Brick title="PRO+"
          theme={BRICK_THEMES.PRO_PLUS}
          periodicity={periodicity}
          prices={{ [PERIODICITIES.MONTHLY]: "30", [PERIODICITIES.YEARLY]: "300" }}
          currency="USD"
          notice={billedNotice}
          features={[
            "30 Projects",
            "All PRO Features +",
            "No Bravemark Branding",
            "PRO+ Badge",
          ]}
          note="More features coming soon"
          buttonLabel="Get Started For Free"
        />
      </div>
    </div>
  )
}


function PeriodicityToggle({periodicity, onClick}) {
  return (
    <div className={moduleStyles.periodicityToggle}>
      Monthly
      <OnOffButton active={periodicity === PERIODICITIES.YEARLY} onChange={onClick} />
      Yearly
    </div>
  )
}

function Brick({theme, title, periodicity, prices, currency, notice, features, note = null, buttonLabel}) {
  const baseClasses = [
    moduleStyles.brick,
    theme,
  ].join(" ")

  return (
    <div className={baseClasses}>
      <div className={moduleStyles.top} />
    
      <div className={moduleStyles.header}>
        <h3>{title}</h3>
        <div className={moduleStyles.price}>
          <Price periodicity={periodicity} prices={prices} currency={currency} />
        </div>
        <div className={moduleStyles.notice}>{notice}</div>
      </div>

      <div className={moduleStyles.features}>
        {features.map((feature, index) => {
          const isString = typeof feature === "string"
          const title = isString ? feature : feature.title
          const more = isString ? null : feature.description

          return (
            <div key={index} className={moduleStyles.feature}>
              <div className={moduleStyles.title}><span>{title}</span><CheckboxCircleFill className={moduleStyles.checkbox} colorDisabled={null} /></div>
              {more ? <div className={moduleStyles.more}>{more}</div> : null}
            </div>
          )
        })}
      </div>

      <Conditional show={!!note}>
        <div className={moduleStyles.note}>
          {note}
        </div>
      </Conditional>

      <div className={moduleStyles.bottom}>
        <Button large className={moduleStyles.cta} to="https://app.bravemark.co/editor/register">{buttonLabel}</Button>
      </div>
    </div>
  )
}


function Price({periodicity, prices, currency}) {
  const currencySymbol = currency === "USD" ? "$" : currency

  const amount = prices[periodicity]

  const priceWithSymbol = (
    <>
      <span className={moduleStyles.currency}>{currencySymbol}</span>
      <span className={moduleStyles.amount}>{amount}</span>
    </>
  )

  let savePill = null

  if (periodicity === PERIODICITIES.YEARLY) {
    const saveAmount = (prices[PERIODICITIES.MONTHLY] * 12) - prices[PERIODICITIES.YEARLY]

    if (saveAmount > 0) {
      savePill = <div className={moduleStyles.pill}>Save {currencySymbol}{saveAmount}</div>
    }
  }

  return (
    <>
      {priceWithSymbol}
      {savePill}
    </>
  )
}